import "./App.css"
import React, { useRef } from "react";
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import Value from './Components/Value';
import Benefit from './Components/Benefit';
import Program from './Components/Program';
import Testimoni from './Components/Testimoni';
import Galeri from './Components/Galeri';
import Bantuan from './Components/Bantuan';
import Footer from "./Components/Footer";
import BottomBanner from './Components/BottomBanner';

function App() {
  const homeRef = useRef(null);
  const valueRef = useRef(null);
  const benefitRef = useRef(null);
  const programRef = useRef(null);
  const testimoniRef = useRef(null);
  const galeriRef = useRef(null);
  const bantuanRef = useRef(null);

  return (
    <div className="App">
      <Navbar
        sections={{
          Home: homeRef,
          Value: valueRef,
          Benefit: benefitRef,
          Program: programRef,
          Testimoni: testimoniRef,
          Galeri: galeriRef,
          Bantuan: bantuanRef
        }}
      />
      <div ref={homeRef}>
        <Home />
      </div>
      <div ref={valueRef}>
        <Value />
      </div>
      <div ref={benefitRef}>
        <Benefit />
      </div>
      <div ref={programRef}>
        <Program />
      </div>
      <div ref={testimoniRef}>
        <Testimoni />
      </div>
      <div ref={galeriRef}>
        <Galeri />
      </div>
      <div ref={bantuanRef}>
        <Bantuan />
      </div>
      <BottomBanner />
      <Footer
        sections={{
          Home: homeRef,
          Value: valueRef,
          Benefit: benefitRef,
          Program: programRef,
          Testimoni: testimoniRef,
          Galeri: galeriRef,
          Bantuan: bantuanRef
        }}
      />
    </div>
  );
}

export default App;
