import React from "react";
import Logo from "../Assets/Logo.svg";
import { BsWhatsapp } from "react-icons/bs";
import { SiTiktok } from "react-icons/si";
import { BsInstagram } from "react-icons/bs";

const Footer = ({ sections }) => {
  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:nescommacademy@gmail.com";
  };

  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <span onClick={() => scrollToSection(sections.Home)}>Value</span>
          <span onClick={() => scrollToSection(sections.Value)}>Benefit</span>
          <span onClick={() => scrollToSection(sections.Program)}>Program</span>
          <span onClick={() => scrollToSection(sections.Galeri)}>Galeri</span>
          <span onClick={() => scrollToSection(sections.Testimoni)}>Testimonials</span>
          <span onClick={() => scrollToSection(sections.Bantuan)}>Bantuan</span>
        </div>
        <div className="footer-section-columns">
          <span onClick={() => window.open("https://wa.me/081233324828")}>
            081233324828 (Kak Galih)
          </span>
          <span onClick={handleEmailClick}>nescommacademy@gmail.com</span>
          <div className="footer-icons">
            <BsWhatsapp onClick={() => window.open("https://wa.me/081233324828")} />
            <SiTiktok onClick={() => window.open("https://www.tiktok.com/@nesscomm_academy")} />
            <BsInstagram onClick={() => window.open("https://www.instagram.com/nesscommacademy/")} />
          </div>
          <span style={{ marginTop: 20 }}>© 2024 All Right Reserved</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
