import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgramImage1 from '../Assets/galeri/galeri-1.JPG';
import ProgramImage2 from '../Assets/galeri/galeri-2.JPG';
import ProgramImage3 from '../Assets/galeri/galeri-3.JPG';
import ProgramImage4 from '../Assets/galeri/galeri-4.JPG';
import ProgramImage5 from '../Assets/galeri/galeri-5.JPG';
import ProgramImage6 from '../Assets/galeri/galeri-6.JPG';
import ProgramImage7 from '../Assets/galeri/galeri-7.JPG';
import ProgramImage8 from '../Assets/galeri/galeri-8.JPG';
import ProgramImage9 from '../Assets/galeri/galeri-9.JPG';
import ProgramImage10 from '../Assets/galeri/galeri-10.JPG';
import ProgramImage11 from '../Assets/galeri/galeri-11.JPG';
import ProgramImage12 from '../Assets/galeri/galeri-12.JPG';
import ProgramImage13 from '../Assets/galeri/galeri-13.JPG';
import ProgramImage14 from '../Assets/galeri/galeri-14.JPG';
import ProgramImage15 from '../Assets/galeri/galeri-15.JPG';
import ProgramImage16 from '../Assets/galeri/galeri-16.JPG';
import ProgramImage17 from '../Assets/galeri/galeri-17.JPG';
import ProgramImage18 from '../Assets/galeri/galeri-18.JPG';
import ProgramImage19 from '../Assets/galeri/galeri-19.JPG';
import ProgramImage20 from '../Assets/galeri/galeri-20.JPG';
import ProgramImage21 from '../Assets/galeri/galeri-21.JPG';
import ProgramImage22 from '../Assets/galeri/galeri-22.JPG';
import ProgramImage23 from '../Assets/galeri/galeri-23.JPG';
import ProgramImage24 from '../Assets/galeri/galeri-24.JPG';
import ProgramImage25 from '../Assets/galeri/galeri-25.JPG';
import ProgramImage26 from '../Assets/galeri/galeri-26.JPG';
import ProgramImage27 from '../Assets/galeri/galeri-27.JPG';
import ProgramImage28 from '../Assets/galeri/galeri-28.JPG';
import ProgramImage29 from '../Assets/galeri/galeri-29.JPG';
import ProgramImage30 from '../Assets/galeri/galeri-30.JPG';

const images = [ProgramImage1, ProgramImage2, ProgramImage3, ProgramImage4, ProgramImage5, ProgramImage6, ProgramImage7, ProgramImage8, ProgramImage9, ProgramImage10];

const images2 = [ProgramImage12, ProgramImage13, ProgramImage14, ProgramImage15, ProgramImage16, ProgramImage17, ProgramImage19, ProgramImage20];

const images3 = [ProgramImage21, ProgramImage22, ProgramImage23, ProgramImage24, ProgramImage25, ProgramImage26, ProgramImage27, ProgramImage28, ProgramImage29, ProgramImage30]

const Gallery = () => {
  const [slidesToShow, setSlidesToShow] = useState(4);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(4);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1000,
    cssEase: "linear"
  };

  return (
    <div className="gallery-section" style={{ backgroundColor:"#46A4E8" }}>
      <div className="gallery-container">
        <h1 className="about-heading">POTRET KEGIATAN</h1>
        <div className="gallery-wrapper">
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index} className="image-wrapper">
                <img src={image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="gallery-wrapper">
          <Slider {...settings}>
            {images2.map((image, index) => (
              <div key={index} className="image-wrapper">
                <img src={image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
        <div className="gallery-wrapper">
          <Slider {...settings}>
            {images3.map((image, index) => (
              <div key={index} className="image-wrapper">
                <img src={image} alt={`Image ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
