// Navbar.js
import React, { useState } from "react";
import Logo from "../Assets/Logo.svg";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import ValueIcon from "@mui/icons-material/BookmarkAdded";
import BenefitIcon from "@mui/icons-material/TrendingUp";
import ProgramIcon from "@mui/icons-material/MenuBook";
import TestimoniIcon from "@mui/icons-material/Favorite";
import GaleriIcon from "@mui/icons-material/ViewCarousel";
import BantuanIcon from "@mui/icons-material/Help";

const Navbar = ({ sections }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = (text) => {
    console.log("Clicked:", text);
    setOpenMenu(false); // Close the menu after a menu item is clicked
    const sectionRef = sections[text];
    if (sectionRef && sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      text: "Value",
      icon: <ValueIcon />,
    },
    {
      text: "Benefit",
      icon: <BenefitIcon />,
    },
    {
      text: "Program",
      icon: <ProgramIcon />,
    },
    {
      text: "Testimoni",
      icon: <TestimoniIcon />,
    },
    {
      text: "Galeri",
      icon: <GaleriIcon />,
    },
    {
      text: "Bantuan",
      icon: <BantuanIcon />,
    },
  ];

  return (
    <div className="navbar" style={{ backgroundColor: "#E0EBF5" }}>
      <nav>
        <div className="nav-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="navbar-links-container">
          {menuOptions.map((item, index) => (
            <a href="#" key={index} onClick={() => handleMenuClick(item.text)}>
              {item.text}
            </a>
          ))}
        </div>
        <div className="navbar-menu-container">
          <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
        </div>
        <Drawer
          open={openMenu}
          onClose={() => setOpenMenu(false)}
          anchor="right"
        >
          <Box
            sx={{
              width: 250,
              display: "flex",
              flexDirection: "column", // Display items vertically
              justifyContent: "flex-start", // Align items to the top
              alignItems: "stretch", // Stretch items to fill the width
            }}
            role="presentation"
            onClick={() => setOpenMenu(false)}
            onKeyDown={() => setOpenMenu(false)}
          >
            <List>
              {menuOptions.map((item, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton onClick={() => handleMenuClick(item.text)}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <Divider />
          </Box>
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
