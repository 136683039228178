import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProgramImage1 from '../Assets/program-1.svg';
import ProgramImage2 from '../Assets/program-2.svg';
import ProgramImage3 from '../Assets/program-3.svg';
import ProgramImage4 from '../Assets/program-4.svg';
import ProgramImage5 from '../Assets/program-5.svg';
import ProgramImage6 from '../Assets/program-6.svg';

const Program = () => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedItemDetails, setSelectedItemDetails] = useState("Klik program di bawah untuk melihat detilnya.");
    const [selectedItemTitle, setSelectedItemTitle] = useState(null);
    const [isMobileView, setIsMobileView] = useState(false);

    // Dummy data for the items
    const items = [
        { id: 1, title: "PELATIHAN DIGITAL UNTUK GURU", details: "Menyediakan pelatihan dalam pemanfaatan teknologi dalam pembelajaran, penggunaan platform E-Learning, dan integrasi alat digital dalam pembelajaran", image: ProgramImage1 },
        { id: 2, title: "PELATIHAN KOMUNIKASI EFEKTIF", details: "Details for Item 2", image: ProgramImage2 },
        { id: 3, title: "PELATIHAN KETERAMPILAN DIGITAL", details: "Details for Item 3", image: ProgramImage3 },
        { id: 4, title: "PELATIHAN LEADERSHIP & COLLABORATING", details: "Details for Item 4", image: ProgramImage4 },
        { id: 5, title: "PELATIHAN MENJADI CONTENT CREATOR", details: "Details for Item 5", image: ProgramImage5 },
        { id: 6, title: "PELATIHAN PUBLIC SPEAKING", details: "Details for Item 6", image: ProgramImage6 },
    ];

    // Function to handle item click
    const handleItemClick = (id, title, details) => {
        setSelectedItem(id);
        setSelectedItemTitle(title);
        setSelectedItemDetails(details);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 1000);
        };

        handleResize(); // Check initial screen width
        window.addEventListener("resize", handleResize); // Add event listener for window resize

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up on unmount
        };
    }, []);

    let sliderRef = useRef(null);
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };
    // Settings for the Slider component
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
        <div className="program-section">
            <div className="program-section-container">
                <div className="program-section-text-container">
                    <h1 className="program-heading">PROGRAM PELATIHAN</h1>
                </div>
                <div className="box-border">
                    {isMobileView ? (
                        <div>
                            <Slider
                                ref={slider => {
                                    sliderRef = slider;
                                }}
                                {...settings}
                            >
                                {items.map((item) => (
                                    <div key={item.id} className="box" onClick={() => handleItemClick(item.id, item.title, item.details)}>
                                        <h3>{item.title}</h3>
                                        <p>{item.details}</p>
                                    </div>
                                ))}
                            </Slider>
                            <div style={{ textAlign: "center" }}>
                                <button className="button" onClick={previous}>
                                    Previous
                                </button>
                                <button className="button" onClick={next}>
                                    Next
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className="">
                            <h2 className="program-title">{selectedItemTitle}</h2>
                            <p className="program-desc">{selectedItemDetails}</p>
                            <div className="box-container">
                                {items.map((item) => (
                                    <div
                                        key={item.id}
                                        className={`box ${selectedItem === item.id ? "selected" : ""}`}
                                        onClick={() => handleItemClick(item.id, item.title, item.details, item.image)} // Pass title, details, and image to handleItemClick
                                    >
                                        <img src={item.image} className="program-image" alt={item.title} />
                                        <h3>{item.title}</h3>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Program;
