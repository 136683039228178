import React, { useState } from "react";
import emailjs from 'emailjs-com';
import { AiFillStar } from "react-icons/ai";
import BantuanImage from "../Assets/bantuan.svg";

const Bantuan = () => {
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    emailjs.sendForm('service_uq0mati', 'template_6oy4fni', e.target, 'E673HlgkQE0S7KN0A')
      .then((result) => {
        console.log(result.text);
        setLoading(false);
        setMessageSent(true);
        setTimeout(() => {
          setMessageSent(false);
        }, 3000); // Clear confirmation message after 3 seconds
        e.target.reset(); // Reset the form
      })
      .catch((error) => {
        console.log(error.text);
        setLoading(false);
        alert('Failed to send message. Please try again later.');
      });
  };

  return (
    <div className="bantuan-section-wrapper" style={{ display: "flex" }}>
      <div className="bantuan-section-top" style={{ flex: "1" }}>
        <h1 className="bantuan-heading">BUTUH BANTUAN?</h1>
        <p className="bantuan-text">
          Jangan ragu untuk menghubungi kami. Kirimkan pesan anda kepada kami dengan mengisi form yang telah disediakan. Kami akan segera membalas pesan anda.
        </p>
      </div>
      <div className="bantuan-form" style={{ flex: "1" }}>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input type="text" id="name" placeholder="Name" name="name" required />
          </div>
          <div className="form-group">
            <input type="email" id="email" placeholder="Email" name="email" required />
          </div>
          <div className="form-group">
            <input type="tel" id="phone" placeholder="Nomor HP" name="phone" required />
          </div>
          <div className="form-group">
            <input type="text" id="subject" placeholder="Subject" name="subject" required />
          </div>
          <div className="form-group">
            <textarea id="message" name="message" placeholder="Pesan" rows="4" required></textarea>
          </div>
          {loading && <p>Loading...</p>}
          {messageSent && <p>Message sent successfully!</p>}
          {!loading && !messageSent && <button type="submit">Kirim</button>}
        </form>
      </div>
    </div>
  );
};

export default Bantuan;
