import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { AiFillStar } from "react-icons/ai";

// Importing dummy image
import DummyImage from "../Assets/Logo.svg";

const Testimoni = () => {
  // Dummy testimonial data
  const testimonials = [
    {
      id: 1,
      image: DummyImage,
      text: "Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.",
      stars: 5,
      name: "John Doe",
    },
    {
      id: 2,
      image: DummyImage,
      text: "Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.",
      stars: 4,
      name: "Jane asd",
    },
    {
      id: 3,
      image: DummyImage,
      text: "Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.",
      stars: 3,
      name: "Alice Smith",
    },
    {
      id: 4,
      image: DummyImage,
      text: "Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.",
      stars: 4,
      name: "Bob Johnson",
    },
    {
      id: 5,
      image: DummyImage,
      text: "Lorem ipsum dolor sit amet consectetur. Non tincidunt magna non et elit. Dolor turpis molestie dui magnis facilisis at fringilla quam.",
      stars: 5,
      name: "Sarah Williams",
    },
  ];

  // State to track window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Update window width on resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Calculate slidesToShow based on window width
  let slidesToShow = 3;
  if (windowWidth <= 1200) {
    slidesToShow = 2;
  }
  if (windowWidth <= 800) {
    slidesToShow = 1;
  }

  // Settings for the carousel
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: "linear",
    slidesToShow: slidesToShow,
  };

  return (
    <div className="testimoni-section" style={{ backgroundColor:"#FFF5E6" }}>
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <h1 className="testimoni-heading">TESTIMONIAL</h1>
        <p className="testimoni-text">
          Apa yang mereka rasakan setelah bergabung bersama kami?
        </p>
      </div>
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <div key={testimonial.id}>
            <div className="testimonial-section-bottom">
              <p>{testimonial.text}</p>
              <div className="testimonials-stars-container">
                {[...Array(testimonial.stars)].map((_, index) => (
                  <AiFillStar key={index} />
                ))}
              </div>
              <h2>{testimonial.name}</h2>
            </div>
          </div>
        ))}
      </Slider>
    </div>
    </div>
  );
};

export default Testimoni;
